*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


h1{
    color: slategray;
    font-size: 16px;
}
.wattTitle{
    background-color: #DE7C09;
}

.wattTitle img{
    width: 100%;
    height: 60svh;
    object-fit:cover;
}

.wattText{
    background-color: #DE7C09;
}

.Testcontainer{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 80%;
    height: 40svh;
    margin-left: 10%;
    margin-right: 10%;

}

.Testbox{
    margin-top: 5vh;
    line-height: 3vh;
    color:white;
}

.summary{
    width: 30vw;
}

.button{
    margin-top: 2svh;
    display: flex;
    border: 2px solid white;
    justify-content: center;
    align-items: center;
    width: 10vw;
    color: #fff;
}

a{
    text-decoration: none;
}

.button:hover{
    background-color: #fff;
    color: black;
    cursor: pointer;
}
    
.goal{
    margin-top: 20vh;
    margin-left: 20%;
    width: 60%;
    height: auto;
    line-height: 3vh;
    text-align: center;
}

.goal p{
    font-size: 20px;
    font-weight: 500;
}

.mockup1{
    width: 100%;
    height: auto;
    justify-content: center;
    display: flex;
}
.mockup1 video{
    width: 70vw;
    height: auto;
    padding: 5vh;
}

.keyChallenges{
    margin-left: 10%;
    margin-right: 10%;
    padding-bottom: 10svh;
    width: 80%;
    height: auto;
}

.key{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.key h2{
    color: #333333;
}

.key p{
    color: #333333;
    font-weight: 500;
}
.column{
    padding-right: 5vw;
}


.row{
    display:flex
}
.mockup2{
    width: 100%;
    height: auto;
    background-color: black;
}

.mockup2 img{
    width: 100%;
    height: auto;
}


.process{
    padding-top: 5vh;
    margin-left: 10vw;
    padding-bottom: 20svh;
    width: 80vw;

}

.process p{
    font-weight: 500;
}

.process img{
    width: 30vw;
    object-fit: cover;
}

.designText{
    margin-top: 5vh;
    width: 30vw;
    line-height: 3vh;
}

.scrum{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10vw;
    width: 80vw;
    padding-top: 5vh;
}
.scrum p{
    text-align: center;
    padding-left: 10vw;
    padding-right: 10vw;
}

.learned h1{
    padding-top: 10vh;
    margin-left: 10vw;
    margin-bottom: -5vh;
}
.lessons{
    display: flex;
    flex-direction: row;
    padding-top: 5vh;
    padding-bottom: 5vh;
    margin-left: 10vw;
    width: 80vw;
    justify-content: center;
    align-items:center;
    text-align: center;
}
.figma{
    transition-delay: 1s;
}
.lessons p{
    margin-top: 5vh;
    font-size: 20px;
}
.lessons img{
    padding-top: 5vh;
    padding-bottom: 5vh;
    width: 40vw;
    height: auto;
}


@media only screen and (max-width: 600px) {
    .wattText h1 {
    font-size: 12px;
    }

    .wattText h2{
        font-size: 14px;
    }
    .wattText p{
        font-size: 16px;
    }


    .button{
        width: 15vw;
    }
    .goal p{
        font-size: 14px;
    }

    .mockup1 video{
        width: 100vw;
    }
    .key{
        flex-direction: column;
    }

    .key h1{
        font-size: 12px;
    }


    .key h2{
        font-size: 16px;
    }

    .key p{
        font-size: 12px;
    }

    .designText{
        width: 80vw;
        font-weight: 500;
    }
    .designText h2{
        font-size: 16px;
    }

    .row{
        flex-direction: column;
    }

    .process img{
        width: 100%;
    }

    .lessons{
        flex-direction: column;
        
    }

    .lessons p{
        font-size: 14px;
    }
    
    }