.title-buttons{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 3svh;
}


.red{
  background-color: #3B76CE !important;
}

.yellow{
  background-color: #DE7C09 !important;
}


@keyframes move {
  100% {
      transform: translate3d(0, 0, 1px) rotate(360deg);
  }
}

.background {
  position: relative;
  width: 100%;
  height: 100svh;
  top: 0;
  left: 0;
  background: #3E1E68;
  overflow: hidden;
}





.background span {
  width: 20vmin;
  height: 20vmin;
  border-radius: 20vmin;
  backface-visibility: hidden;
  position: absolute;
  animation: move;
  animation-duration: 45;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}


.background span:nth-child(0) {
  color: #FFACAC;
  top: 38%;
  left: 52%;
  animation-duration: 15s;
  animation-delay: -8s;
  transform-origin: -14vw 19vh;
  box-shadow: 40vmin 0 5.099719143651482vmin currentColor;
}
.background span:nth-child(1) {
  color: #583C87;
  top: 74%;
  left: 33%;
  animation-duration: 32s;
  animation-delay: -9s;
  transform-origin: -13vw 13vh;
  box-shadow: 40vmin 0 5.445953721622764vmin currentColor;
}
.background span:nth-child(2) {
  color: #E45A84;
  top: 5%;
  left: 16%;
  animation-duration: 20s;
  animation-delay: -13s;
  transform-origin: -15vw 9vh;
  box-shadow: 40vmin 0 5.33124969274112vmin currentColor;
}
.background span:nth-child(3) {
  color: #583C87;
  top: 15%;
  left: 75%;
  animation-duration: 27s;
  animation-delay: -40s;
  transform-origin: -3vw 20vh;
  box-shadow: -40vmin 0 5.8340248296034405vmin currentColor;
}
.background span:nth-child(4) {
  color: #E45A84;
  top: 25%;
  left: 35%;
  animation-duration: 16s;
  animation-delay: -7s;
  transform-origin: -21vw -15vh;
  box-shadow: -40vmin 0 5.012822762190339vmin currentColor;
}
.background span:nth-child(5) {
  color: #FFACAC;
  top: 98%;
  left: 80%;
  animation-duration: 38s;
  animation-delay: -31s;
  transform-origin: -17vw 18vh;
  box-shadow: -40vmin 0 5.407168338629115vmin currentColor;
}
.background span:nth-child(6) {
  color: #583C87;
  top: 72%;
  left: 78%;
  animation-duration: 34s;
  animation-delay: -34s;
  transform-origin: -7vw -17vh;
  box-shadow: 40vmin 0 5.744348021548419vmin currentColor;
}
.background span:nth-child(7) {
  color: #FFACAC;
  top: 24%;
  left: 30%;
  animation-duration: 17s;
  animation-delay: -10s;
  transform-origin: 24vw 16vh;
  box-shadow: -40vmin 0 5.767853604369831vmin currentColor;
}
.background span:nth-child(8) {
  color: #FFACAC;
  top: 12%;
  left: 45%;
  animation-duration: 33s;
  animation-delay: -2s;
  transform-origin: 12vw 5vh;
  box-shadow: 40vmin 0 5.412375604672759vmin currentColor;
}
.background span:nth-child(9) {
  color: #E45A84;
  top: 87%;
  left: 33%;
  animation-duration: 23s;
  animation-delay: -37s;
  transform-origin: 19vw 21vh;
  box-shadow: 40vmin 0 5.2891875816012375vmin currentColor;
}
.background span:nth-child(10) {
  color: #FFACAC;
  top: 5%;
  left: 9%;
  animation-duration: 21s;
  animation-delay: -44s;
  transform-origin: 14vw 8vh;
  box-shadow: -40vmin 0 5.376144654124523vmin currentColor;
}
.background span:nth-child(11) {
  color: #E45A84;
  top: 2%;
  left: 16%;
  animation-duration: 33s;
  animation-delay: -29s;
  transform-origin: 24vw 11vh;
  box-shadow: -40vmin 0 5.584554178275799vmin currentColor;
}
.background span:nth-child(12) {
  color: #583C87;
  top: 100%;
  left: 45%;
  animation-duration: 46s;
  animation-delay: -29s;
  transform-origin: -10vw 8vh;
  box-shadow: 40vmin 0 5.127179717899987vmin currentColor;
}
.background span:nth-child(13) {
  color: #583C87;
  top: 38%;
  left: 28%;
  animation-duration: 47s;
  animation-delay: -47s;
  transform-origin: 4vw 13vh;
  box-shadow: 40vmin 0 5.288129937743677vmin currentColor;
}
.background span:nth-child(14) {
  color: #E45A84;
  top: 12%;
  left: 26%;
  animation-duration: 33s;
  animation-delay: -42s;
  transform-origin: 12vw 4vh;
  box-shadow: -40vmin 0 5.173223737533418vmin currentColor;
}
.background span:nth-child(15) {
  color: #583C87;
  top: 23%;
  left: 72%;
  animation-duration: 39s;
  animation-delay: -44s;
  transform-origin: -3vw -9vh;
  box-shadow: -40vmin 0 5.874981508912445vmin currentColor;
}
.background span:nth-child(16) {
  color: #583C87;
  top: 94%;
  left: 43%;
  animation-duration: 53s;
  animation-delay: -6s;
  transform-origin: -14vw -22vh;
  box-shadow: 40vmin 0 5.792325848122574vmin currentColor;
}
.background span:nth-child(17) {
  color: #E45A84;
  top: 65%;
  left: 66%;
  animation-duration: 38s;
  animation-delay: -17s;
  transform-origin: -6vw 17vh;
  box-shadow: 40vmin 0 5.951537309006654vmin currentColor;
}
.background span:nth-child(18) {
  color: #583C87;
  top: 32%;
  left: 28%;
  animation-duration: 47s;
  animation-delay: -33s;
  transform-origin: -20vw 25vh;
  box-shadow: 40vmin 0 5.393976991025538vmin currentColor;
}
.background span:nth-child(19) {
  color: #583C87;
  top: 92%;
  left: 49%;
  animation-duration: 36s;
  animation-delay: -3s;
  transform-origin: -2vw -18vh;
  box-shadow: 40vmin 0 5.8940478214908225vmin currentColor;
}


@media (max-width: 768px) {
  .title-buttons{
    flex-direction: column;
  }
}