.black-page{
    background-color: black;
    color: white;
}

.black-page .tech{
    color: black;
    background-color: #A63D40;
}



.shflTitle img{
    width: 100%;
    height: 60svh;
    object-fit:cover;
}

.shfl-goal{
    padding-top: 20vh;
    margin-left: 20vw;
    padding-bottom: 20svh;
    width: 60vw;
    height: auto;
    line-height: 3vh;
    text-align: center;
}

.shfl-goal p{
    font-size: 20px;
    font-weight: 500;
}

.shfl{
    color: red;
}

.mockup{
    height: auto;
    justify-content: center;
    display: flex;
    padding-bottom: 15svh;
    
}

.mockup video{
    max-width: 20vw;
}
.mockup img{
    object-fit:cover;
    width: 100%;
}

.shfl-keyChallenges{
    padding-top: 10svh;
    background-color: #A63D40;
}
.shfl-keyChallenges h1{
    color: white;
}
.shfl-key{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}



.shfl-process h1{
    color: white;
}


.shfl-tech{
    padding-top: 5svh;
    padding-bottom: 5svh;
}

.shfl-tech h1{
    color: white;
}



.shfl-learned h1{
    color: white;
}


@media only screen and (max-width: 600px) {
    .shflText h1 {
    font-size: 12px;
    }

    .shflText h2{
        font-size: 14px;
    }
    .shflText p{
        font-size: 16px;
    }
    .shfl-key{
        flex-direction: column;
    }
}