@font-face {
    font-family: 'Abril Fatface';
    src: url('../../elements/fonts/Abril_Fatface/AbrilFatface-Regular.ttf')format('woff2'),
         url('../../elements/fonts/Abril_Fatface/AbrilFatface-Regular.ttf') format('woff');
    /* Add other font formats if available, e.g., .ttf, .eot */
    /* You can also add a font-weight property if the font supports multiple weights */
    font-weight: normal;
    font-style: normal;
  }
  

*{
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    position: relative;    
    
}

.banner{
    position:relative;
    width: 100%;
    height: 100svh;
    display: flex;

}

.banner img{
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.banner video{
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.banner h2{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background:white;
    font-size: 30vw;
    font-weight: 900;
    text-align: center;
    text-transform: uppercase;
    color: #000;
    line-height:100svh;
    mix-blend-mode: screen;

}

.bottomText{
    display: flex;
    flex-direction: row;
    top: 90svh;
    position:absolute;
    width: 100%;
    mix-blend-mode: multiply;
    justify-content: space-between;
}

.banner p{
    color: #000;
    mix-blend-mode: multiply;
}

.leoBox{
    width: 30vw;
}

.leo{
    padding-left: 5vw;
}

.location{
    padding-right: 5vw;
}

.textBox{
    
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: #fff;
}

.textBox p{
    background-image: linear-gradient(to right, red, black);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-weight: bold;
    text-align: center;
    margin-top: 30svh;
    margin-bottom: 30svh;
    font-size: 25px;
    padding-left: 20vw;
    padding-right: 20vw;
}

.hidden{
    opacity: 0;
    filter: blur(5px);
    transform: translateX(-100%);
    transition: all 1s;
}

.show{
 opacity: 1;
 filter: blur(0);
 transform: translateX(0);
}

.workSection{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    background-color: #fff;
    padding-bottom: 15svh;
}

.workSection h2{
    color: #252525;
    text-decoration: none;
}

.workSection p{
    color: #333333;
    text-decoration: none;
    
}

.workSection h1{
    color: #333333;
    padding-bottom: 1vh;

}

.mainWorkSection{
    margin-left: 10vw;
    margin-right: 10vw;
}

.box1{
    width: 80vw;
    height: 50vh;
    margin-top: 5vh;
}

.box1 img{
    object-fit: cover;
    width: 80vw;
    height: 45vh;
    transition: transform 0.5s ease;
}

.roww{
    margin-top: 4vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.box{
    margin-top: 5vh;
    width: 38vw;
    height:auto;
}

.box img{
    object-fit:cover;
    width: 38vw;
    height: 35vh;
    transition: transform 0.5s ease;
}

.listen-box{
    margin-top: 5svh;
    width: 80vw;
    height: 45svh !important;
    transition: transform 0.5s ease;
    background-color: #252525;
    display: flex;
    justify-content: center !important;
    align-items: center;
}

.listen-box p{
    font-size: 80px !important;
}

.listen-box:hover{
    opacity: 0.8;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.6);
    transform: scale(1.05);
}

.workSection img:hover{
    opacity: 0.8;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.6);
    transform: scale(1.05);
}

.skills{
    width: 100vw;
    height: 100vh;
    background-color: #333333;
}

@media only screen and (max-width: 600px) {

    .leoBox{
        width: 50vw;
    }

    .workSection h1{
        margin-bottom: -5svh;
        text-align: center;
    }
    
    .roww{
        flex-direction: column;
    }

    .box{
        width: 80vw;
    }
    .box img{
        width: 80vw;
    }


    .listen-box p{
        font-size: 50px !important;
    }

    

}