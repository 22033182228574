/* Fade-in and fade-out animations */
.page-enter {
    opacity: 0;
  }
  
  .page-enter-active {
    opacity: 1;
    transition: opacity 300ms;
  }
  
  .page-exit {
    opacity: 1;
  }
  
  .page-exit-active {
    opacity: 0;
    transition: opacity 300ms;
  }
  