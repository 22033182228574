.main{
    top: 0;
    left: 0%;
    right: 0%;

}

.main p{
    font-weight: 500;
}

.main h1{
    color: #A63D40;
}

.top-bar{
    height: auto;
    background-color: #A63D40;
    display: flex;
    flex-direction: row;
}
.top-bar h1{
    margin-top: 10vh;
    margin-left: 3vw;
    margin-bottom: 2vh;
    font-size: 20px;
    font-weight: 500;
    color: white;
}

.top-bar img{
    object-fit: contain;    
}

.text{
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fill{
    padding-bottom: 15svh;
}

.experience{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 15svh;
    margin-left: 10vw;
    width: 80vw;
}

.job{
    margin-top: 4vh;
}

.width{
    width: 40%;
}

.rows{
    margin-top: 0vh;
}



.experience h1{
    font-size: 16px;
}

.experience h2{
    font-size: 14px;
}



.horizontal-line{
    border-top: 1px solid black;
    margin-left: 10vw;
    width: 80vw;
    margin-top: 5svh;
}

@media only screen and (max-width: 600px) {
    .top-bar{
        flex-direction: column;
    }

    .text{
        margin-left: 5vw;
        margin-right: 5vw;
    }
    
    .top-bar img{
        width: 40vw;
        justify-content: center;
        text-align: center;
    }

    .experience{
        flex-direction: column;
    }
    .width{
        width: 100%;
    }
    
}